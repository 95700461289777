import {
  WORK_AFTER_APPEAL,
  WORK_ANALYZED,
  WORK_NOT_ANALYZED,
  WORK_ON_ANALYZE,
  WORK_ON_APPEAL,
} from "@/constants/works";
/**
 * Возвращает человекопонятную строку со статусом работы
 * @param {object}  work
 * @param {number} work.appeal_expert - id эксперта, иначе 0
 * @param {string} work.status - статус работы
 * @return {string} статус работы
 */
export const getWorkStatus = (work) => {
  if (work && typeof work === "object") {
    // Если работа в статусе `WORK_ON_APPEAL` то нужно смотреть
    // назначен эксперт или нет. во всех остальных кейсах,
    // все тривиально =)
    if (work.status === WORK_ON_APPEAL && work.appeal_expert) {
      return "На проверке у апелляционного жюри";
    } else if (work.status === WORK_ON_APPEAL && !work.appeal_expert) {
      return "Работа подана на апелляцию";
    }
    switch (work.status) {
      case WORK_ON_ANALYZE:
        return "Работа на проверке";
      case WORK_NOT_ANALYZED:
        return "Работа загружена";
      case WORK_ANALYZED:
        return "Работа проверена";
      case WORK_AFTER_APPEAL:
        return "Апелляция завершена";
      default:
        return "Неизвестный статус";
    }
  }
  return "";
};
