<template>
  <div class="team-find">
    <BaseModalContent
      :dynamic="true"
      :closable="false"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-s">Задания повышенной сложности</h2>
      <div class="typography mb-m">
        <p>
          Задания по&nbsp;некоторым предметам делятся на&nbsp;два уровня: для
          8&minus;9 классов (проще) и&nbsp;для 10&minus;11 классов (сложнее).
          Участники из&nbsp;8&minus;9 классов могут выбрать более сложный
          вариант, но&nbsp;есть несколько важных моментов:
        </p>
        <ul>
          <li>
            На&nbsp;II&nbsp;отборочном этапе объединиться в&nbsp;команды можно
            только с&nbsp;участниками, которые решали задания того&nbsp;же
            уровня. Если вы&nbsp;учитесь в&nbsp;8&nbsp;или 9&nbsp;классе,
            но&nbsp;хотите объединиться в&nbsp;команду с&nbsp;участниками
            из&nbsp;10&nbsp;или 11&nbsp;класса, вам нужно выбрать более сложный
            вариант&nbsp;&mdash; для 10&minus;11&nbsp;классов.
          </li>
          <li>
            На&nbsp;финале вы&nbsp;тоже будете решать предметный тур повышенной
            сложности (10&minus;11&nbsp;класс).
          </li>
          <li>
            Нельзя решать один предмет за&nbsp;8&minus;9&nbsp;класс,
            а&nbsp;другой&nbsp;&mdash; за&nbsp;10&minus;11. Например, если
            в&nbsp;вашем профиле требуются математика и&nbsp;физика, нельзя
            решать математику для 10&minus;11&nbsp;классов,
            а&nbsp;физику&nbsp;&mdash; для 8&minus;9&nbsp;классов.
          </li>
          <li>
            Можно решать оба варианта, если вам хватит времени и&nbsp;сил.
          </li>
        </ul>
        <p>
          Мы&nbsp;советуем выбирать более сложный вариант, только если
          вы&nbsp;планируете объединяться в&nbsp;команду с&nbsp;участниками
          из&nbsp;10&minus;11&nbsp;классов. Вам просто интересно, как выглядят
          задания повышенной сложности? Загляните в&nbsp;сборники прошлых лет
          на&nbsp;<a
            class="link"
            href="https://ntcontest.ru/study/problembooks/"
            rel="noopener noreferrer"
            target="_blank"
            >нашем сайте</a
          >.
        </p>
        <p v-if="currentStage">
          Вы уверены, что хотите решать задания повышенной сложности по профилю
          &laquo;{{ profile.title }}&raquo;? (Изменить выбор, вы сможете на
          странице профиля до окончания I отборочного этапа)
        </p>
        <p v-else>
          Изменить эту настройку можно на странице Профиля, в блоке с Заданиями.
        </p>
      </div>
      <template v-if="currentStage">
        <BaseButton @click="handleSetTrack(upperTrack)">Да, хочу</BaseButton
        >&emsp;
        <BaseButton @click="handleSetTrack(lowerTrack)"
          >Нет, не хочу</BaseButton
        >
      </template>
      <BaseButton
        v-else
        @click="$emit('close')"
        >Закрыть</BaseButton
      >
    </BaseModalContent>
  </div>
</template>

<script>
import { INDIVIDUAL_STAGE, LOW_SCHOOL_ALIAS, SCHOOL_ALIAS } from "@/constants";
import { request } from "@/services/api";
/**
 * Модалка с объяснением про уровни профиля (для 8-9 классов)
 * И с возможность выбора `prefered_next_step_track` у профиля
 * Если пользователь выбирает старший трек, то ему должны быть доступны
 * Активности как для 8-9 так и для 10-11 классов.
 * Если выбирает младший трек - то только активности для 8-9
 */

export default {
  name: "ProfieTrackSelectModal",
  props: {
    profileId: {
      type: Number,
    },
  },
  computed: {
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    currentStage() {
      return this.$store.state.participant.profiles?.find(
        (participantProfile) => {
          return (
            participantProfile.profile_id === this.profileId &&
            participantProfile.stage === INDIVIDUAL_STAGE
          );
        }
      );
    },
    upperTrack() {
      return Object.values(this.$store.state.tracks)?.find(
        (n) => n.alias === SCHOOL_ALIAS
      )?.id;
    },
    lowerTrack() {
      return Object.values(this.$store.state.tracks)?.find(
        (n) => n.alias === LOW_SCHOOL_ALIAS
      )?.id;
    },
  },
  methods: {
    /**
     * Устанавливает предпочтительный трек для
     * выбранного профиля пользователя
     * @params {Number} track_id
     */
    async handleSetTrack(track_id) {
      if (!track_id) return;
      try {
        await request({
          method: "PATCH",
          url: `/profiles/${this.profileId}`,
          data: {
            stage: INDIVIDUAL_STAGE,
            prefered_next_step_track: track_id,
          },
        });
        this.$store.commit("participant/CHANGE_PROFILE", {
          ...this.currentStage,
          prefered_next_step_track: this.$store.state.tracks[track_id],
        });
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось сохранить выбор.",
          message: error.message,
        });
      }
      this.$emit("close");
    },
  },
};
</script>

<style></style>
