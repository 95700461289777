<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <h2 class="text-bold text-size-h2 mb-m">
      <span v-if="success">Заявление принято</span>
      <span v-else>
        Подать заявление на апелляцию по предмету {{ subject.title }}</span
      >
    </h2>
    <div v-if="success">
      <div class="typography mb-l">
        <p>
          Вы&nbsp;успешно подали заявление на&nbsp;апелляцию по&nbsp;предмету
          {{ subject.title }}. Результаты вы&nbsp;увидите в&nbsp;Личном
          кабинете.
        </p>
        <p>
          О&nbsp;дате и&nbsp;времени проведения личной апелляции в&nbsp;Zoom
          мы&nbsp;сообщим в&nbsp;чате финалистов профиля, по&nbsp;электронной
          почте и&nbsp;в&nbsp;Личном кабинете.
        </p>
      </div>
      <BaseButton
        theme="primary"
        @click="$emit('close')"
        >Закрыть</BaseButton
      >
    </div>
    <div v-else>
      <div class="typography mb-m">
        <p>
          Это поле для вашего заявления на&nbsp;апелляцию. Укажите в&nbsp;нем
          номер или название задачи и&nbsp;причину несогласия с&nbsp;оценкой.
          Если таких задач несколько, перечислите их&nbsp;все и&nbsp;укажите,
          почему вы&nbsp;не&nbsp;согласны с&nbsp;оценкой по&nbsp;каждой.
          По&nbsp;одной работе нужно подавать только одно заявление
          на&nbsp;апелляцию, даже если она учитывается в&nbsp;нескольких
          профилях (например, если вы&nbsp;1&nbsp;марта решали математику для
          двух профилей, подавайте одно заявление).
        </p>
        <p>
          Мы&nbsp;советуем апеллировать только к&nbsp;тому, что есть
          в&nbsp;вашей работе и&nbsp;четко аргументировать свою точку зрения.
          Апелляции к&nbsp;условиям задач, критериям оценивания, работам других
          участников и&nbsp;причинам дисквалификации не&nbsp;рассматриваются.
        </p>
        <p>
          Жюри рассмотрит ваше заявление и&nbsp;примет решение об&nbsp;изменении
          баллов. В&nbsp;спорных случаях вы&nbsp;сможете защитить свою работу
          на&nbsp;встрече в&nbsp;Zoom.
        </p>
      </div>
      <BaseLoadingBox :pending="pending">
        <ValidationObserver
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|min:20"
            tag="div"
            class="mb-m">
            <label
              for="appeal_reason"
              class="form-label mb-s text-bold"
              >Ваше заявление</label
            >
            <BaseInput
              id="appeal_reason"
              v-model="reason"
              type="textarea"
              placeholder="ваше заявление"
              name="reason"
              rows="10"
              :errors="errors" />
          </ValidationProvider>

          <div
            v-if="error"
            class="mt-m mb-m form-error">
            Не удалось подать апелляцию. {{ error }}
          </div>
          <BaseButton
            type="submit"
            theme="primary"
            class="mr-s"
            >Подать заявление</BaseButton
          >
          <BaseButton
            type="button"
            theme="primary-border"
            @click="$emit('close')"
            >Отмена</BaseButton
          >
        </ValidationObserver>
      </BaseLoadingBox>
    </div>
  </BaseModalContent>
</template>
<script>
import { talentRequest } from "@/services/api";
import { WORK_ON_APPEAL } from "@/constants/works";

export default {
  name: "AppealModal",
  props: {
    subject: {
      type: Object,
      required: true,
    },
    work: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      success: false,
      error: "",
      reason: "",
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      this.error = "";
      try {
        await talentRequest({
          method: "POST",
          url: `${this.$store.state.ntoToolsApiURL}/works/${this.subject?.id}/appeal/${this.work?.id}/`,
          data: {
            reason: this.reason,
          },
        });
        // Пропатчить работу в сторе новым статусом
        this.$store.commit("participant/PATCH_WORK", {
          ...this.work,
          status: WORK_ON_APPEAL,
        });
        this.success = true;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
