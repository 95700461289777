/**
 * Костанты из сервиса Проверки офлайн работ
 * ака nto-tools
 */

// Типы предметов
export const SUBJECT_ONLINE = "online";
export const SUBJECT_OFFLINE = "offline";
export const SUBJECT_STEPIK = "stepik";
export const SUBJECT_STEPIK_ONLINE = "stepik_online";
export const SUBJECT_STEPIK_OFFLINE = "stepik_offline";
export const SUBJECT_EXTERNAL_PLATFORM = "external_platform";

// Статусы работ
export const WORK_NOT_ANALYZED = "not_analyzed";
export const WORK_ON_ANALYZE = "on_analyze";
export const WORK_ANALYZED = "analyzed";
export const WORK_ON_APPEAL = "on_appeal";
export const WORK_AFTER_APPEAL = "after_appeal";
