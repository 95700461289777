<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <h2 class="text-bold text-size-h2">
      Работа по предмету: {{ subject.title }}
    </h2>
    <BaseLoadingBox
      :pending="pending"
      min-height="250px">
      <div>Статус работы: {{ workStatus.toLowerCase() }}</div>
      <div v-if="canDownload">
        <a
          :href="`/client/talent${$store.state.ntoToolsApiURL}/works/${subject.id}/download/`"
          class="link panel-control"
          target="_blank"
          rel="noopener noreferrer"
          download
          >Скачать работу</a
        >
      </div>
      <h4 class="text-bold text-size-h4 mb-s mt-m">Табель оценок</h4>
      <div v-if="work">
        <div
          v-if="error"
          class="form-error mb-m">
          Не удалось получить оценки по работе. {{ error }}
        </div>
        <template v-if="scoresList.length">
          <table class="score-table">
            <thead>
              <tr>
                <th width="40px">№</th>
                <th class="score-table__score">Оценка</th>
                <th>Комментарии</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in scoresList"
                :key="item.id">
                <td>{{ idx + 1 }}</td>
                <td class="score-table__score">
                  <div
                    class="score-result"
                    :class="{ 'has-appeal': item.hasAppeal }">
                    <span
                      class="score-result__analyze"
                      title="Оценка проверяющего эксперта"
                      >{{ item.score || 0 }}</span
                    >
                    <span
                      v-if="item.hasAppeal"
                      class="score-result__appeal"
                      :class="{
                        'color-success': item.appeal_score > item.score,
                        'color-error': item.appeal_score < item.score,
                      }"
                      title="Оценка апелл. жюри">
                      {{ item.appeal_score }}
                    </span>
                  </div>
                </td>
                <td>
                  <div
                    v-if="item.comment"
                    class="score-comment">
                    <div class="score-comment__label">
                      Комментарий эксперта:
                    </div>
                    <div class="score-comment__text">{{ item.comment }}</div>
                  </div>
                  <div
                    v-if="item.appeal_comment"
                    class="score-comment">
                    <div class="score-comment__label">
                      Комментарий апелл. жюри:
                    </div>
                    <div class="score-comment__text">
                      {{ item.appeal_comment }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="typography mt-s">
            <strong
              >Итого: {{ scoresTotal }}
              {{ getNumerals(scoresTotal) }}
            </strong>
          </div>
        </template>

        <div v-else>Нет данных по оценкам</div>

        <div
          v-if="work.analyze_comment"
          class="typography mt-m mb-m">
          <strong>Комментарий эксперта:</strong>
          <p>{{ work.analyze_comment }}</p>
        </div>
        <div
          v-if="work.appeal_comment"
          class="typography mt-m mb-m">
          <strong>Комментарий апелляционного жюри:</strong>
          <p>{{ work.appeal_comment }}</p>
        </div>
        <div
          v-if="work.status === WORK_ANALYZED && canRequestAppeal"
          class="mt-m">
          <p class="mb-l">
            Если вы&nbsp;не&nbsp;согласны с&nbsp;оценкой, можете подать
            заявление на&nbsp;апелляцию до {{ formattedAppelDeadline }}.
          </p>
          <div class="mt-l"></div>
          <BaseButton
            theme="primary"
            class="mr-s mb-s"
            @click.prevent="$emit('close')"
            >Закрыть</BaseButton
          >
          <BaseButton
            theme="primary-border"
            :disabled="!canRequestAppeal"
            @click.prevent="openAppealModal"
            >Подать заявление на&nbsp;апелляцию
          </BaseButton>
        </div>
        <div v-else>
          <BaseButton
            theme="primary"
            class="mr-s mt-l"
            @click.prevent="$emit('close')"
            >Закрыть</BaseButton
          >
        </div>
      </div>
      <div v-else>
        <p>Не удалось получить работу с id ${workId}.</p>
        <BaseButton
          theme="primary"
          class="mt-l"
          @click.prevent="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
    </BaseLoadingBox>
  </BaseModalContent>
</template>

<script>
import { talentRequest } from "@/services/api";
import AppealModal from "./AppealModal.vue";
import { getWorkStatus } from "@/utils/works";
import {
  SUBJECT_OFFLINE,
  SUBJECT_ONLINE,
  SUBJECT_STEPIK_ONLINE,
  WORK_AFTER_APPEAL,
  WORK_ANALYZED,
  WORK_NOT_ANALYZED,
  WORK_ON_ANALYZE,
} from "@/constants/works";
import { numCases } from "@/utils";
import { DATE_FROMAT_WITH_UTC, MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import dayjs from "@/plugins/dayjs";
export default {
  name: "WorkDetailModal",
  props: {
    workId: {
      type: Number,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: true,
      error: false,
      scores: {},
      WORK_ANALYZED,
    };
  },
  computed: {
    scoresTotal() {
      const { scoresList } = this;
      const result = scoresList.reduce((acc, value) => {
        if (value.hasAppeal) {
          acc += value.appeal_score;
        } else {
          acc += value.score;
        }
        return acc;
      }, 0);
      return parseFloat(result.toFixed(2));
    },
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    scoresList() {
      const { scores, work } = this;
      // Если нет баллов, или работа еще не проверялась,
      //  или еще проверяется то вернем пустой массив
      if (
        !scores?.analyze_scores ||
        [WORK_NOT_ANALYZED, WORK_ON_ANALYZE].includes(work?.status)
      )
        return [];

      let result = scores.analyze_scores.reduce((acc, value) => {
        acc[value.task_sequence_number] = value;
        return acc;
      }, {});

      // Если апелляция была проведена и есть
      // баллы апелляционного эксперта
      if (work?.status === WORK_AFTER_APPEAL && scores?.appeal_scores) {
        scores.appeal_scores.reduce((acc, value) => {
          const analyzeScore = acc[value.task_sequence_number];
          // если это пересмотренная оценка
          if (analyzeScore) {
            acc[value.task_sequence_number] = {
              ...analyzeScore,
              appeal_score: value.score,
              appeal_comment: value.comment,
              hasAppeal: value.score || value.score === 0,
            };
          } else {
            // если это новая оценка
            acc[value.task_sequence_number] = {
              ...value,
              score: 0,
              comment: "",
              appeal_score: value.score,
              appeal_comment: value.comment,
              hasAppeal: value.score || value.score === 0,
            };
          }
          return acc;
        }, result);
      }
      return Object.values(result);
    },
    workStatus() {
      const { work } = this;
      if (!work) return "";
      return getWorkStatus(work);
    },
    work() {
      return this.$store.state.participant.works[this.subject?.id];
    },
    formattedAppelDeadline() {
      const { subject } = this;
      if (!subject?.appeal_deadline) return "";
      return dayjs(subject.appeal_deadline).format(DATE_FROMAT_WITH_UTC);
    },
    canRequestAppeal() {
      const { subject, work } = this;
      return (
        work?.status === WORK_ANALYZED &&
        subject?.subject_type !== SUBJECT_OFFLINE &&
        dayjs(this.syncTimeStamp).isBefore(subject?.appeal_deadline)
      );
    },
    canDownload() {
      return [SUBJECT_ONLINE, SUBJECT_STEPIK_ONLINE].includes(
        this.subject?.subject_type
      );
    },
  },
  mounted() {
    this.getScores();
  },
  methods: {
    openAppealModal() {
      this.$modal.show(
        AppealModal,
        {
          subject: this.subject,
          work: { ...this.work },
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    async getScores() {
      this.pending = true;
      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `${this.$store.state.ntoToolsApiURL}/works/${this.subject?.id}/${this.workId}/`,
        });
        this.scores = {
          analyze_scores: data?.analyze_scores,
          appeal_scores: data?.appeal_scores,
        };
        if (data.work) {
          this.$store.commit("participant/PATCH_WORK", data.work);
        }
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    getNumerals(value = 0) {
      return numCases(["балл", "балла", "баллов"], value);
    },
  },
};
</script>

<style lang="less">
.score-result {
  &__analyze,
  &__appeal {
    display: inline-block;
  }

  &__analyze {
    margin-right: 0.5em;
  }

  &.has-appeal &__analyze {
    opacity: 0.4;
    text-decoration: line-through;
    color: @base-color;
  }
}
.score-comment {
  &__label {
    font-size: 13px;
    opacity: 0.8;
  }
  & + & {
    margin-top: 20px;
  }
}
.score-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  &__score {
    width: 100px;
    font-weight: bold;
  }

  th {
    font-weight: normal !important;
  }
  th,
  td {
    padding: 5px 10px;
  }

  tbody tr:nth-child(even) td {
    background-color: @bg-color;
  }

  td {
    border-top: 1px solid darken(@bg-color, 5%);
  }
}
</style>
