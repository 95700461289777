<template>
  <div>
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-m">
        {{ success ? "Решение загружено" : "Загрузить работу" }}
      </h2>

      <div v-if="success">
        <div class="typography mb-m">
          <p>
            Вы успешно загрузили решение заключительного этапа профиля
            &laquo;<strong>{{ profile && profile.title }}</strong
            >&raquo; по предмету &laquo;<strong>{{ subject.title }}</strong
            >&raquo;
          </p>
          <p>
            Если вы&nbsp;загрузили неверные файлы, то&nbsp;можете загрузить
            их&nbsp;заново
            <strong>до&nbsp;{{ subject.formattedUploadDeadline }}</strong
            >. Проверена будет только последняя версия файлов.
          </p>
        </div>
        <BaseButton
          theme="primary"
          type="button"
          @click="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
      <div v-else>
        <div class="typography mb-m">
          <div class="mb-m">
            <p>
              Загрузите решение заключительного этапа профиля &laquo;<strong>{{
                profile && profile.title
              }}</strong
              >&raquo; по предмету &laquo;<strong>{{ subject.title }}</strong
              >&raquo;. Файлы должны быть в формате PDF или JPG.
            </p>
            <p>
              <strong>Будьте внимательны!</strong><br />
              Титульный лист нужно загрузить отдельным файлом, без решения,
              в&nbsp;поле &laquo;<label
                class="link link--pseudo"
                for="work_title"
                >Титульный лист</label
              >&raquo;. Файлы с&nbsp;решением (без титульного
              листа)&nbsp;&mdash; в&nbsp;поле &laquo;<label
                for="work_solutions"
                class="link link--pseudo"
                >Решение</label
              >&raquo;.
            </p>
            <p>
              Если вы&nbsp;загрузили неверные файлы, вы&nbsp;можете загрузить
              файлы еще раз
              <strong>до&nbsp;{{ subject.formattedUploadDeadline }}</strong
              >. После этого загрузить файлы не&nbsp;получится. Проверена будет
              только последняя версия загруженных файлов, все предыдущие будут
              удалены.
            </p>
          </div>
        </div>
        <BaseLoadingBox :pending="pending">
          <ValidationObserver
            ref="form"
            tag="form"
            @submit.prevent="handleSubmit">
            <div class="form-row">
              <ValidationProvider
                v-slot="{ errors, validate }"
                ref="title_page"
                rules="required">
                <label
                  class="form-label"
                  for="work_title"
                  >Титульный лист</label
                >
                <FileInput
                  id="work_title"
                  accept="image/jpeg,image/jpg,application/pdf"
                  type="file"
                  name="title_page"
                  :errors="errors"
                  @change="handleFileChange($event, validate)"
                  @reset="handleFileReset('title_page')" />
                <div class="text-size-xs mt-xs">1 Файл JPG или PDF</div>
              </ValidationProvider>
            </div>

            <div class="form-row">
              <ValidationProvider
                v-slot="{ errors, validate }"
                ref="solutions"
                rules="required|solutions">
                <label
                  class="form-label"
                  for="work_solutions"
                  >Решение</label
                >
                <FileInput
                  id="work_solutions"
                  :accept="ALLOWED_SOLUTION_TYPES.join(',')"
                  type="file"
                  multiple
                  placeholder="Выбрать файлы"
                  name="solutions"
                  :errors="errors"
                  @change="handleFileChange($event, validate)"
                  @reset="handleFileReset('solutions')" />
                <div class="text-size-xs mt-xs">Файлы .jpg или .pdf</div>
              </ValidationProvider>
            </div>
            <div class="mt-m mb-m">
              <p>
                Если у&nbsp;вас возникли сложности с&nbsp;загрузкой работы,
                обратитесь
                <a
                  class="link"
                  :href="`mailto:${$store.state.supportEmail}?subject=Проблема с загрузкой работ заключительного этапа. Talent ID: ${user.talent_id}`"
                  >в&nbsp;службу поддержки</a
                >
                ({{ $store.state.supportEmail }}). Прикрепите файлы работы
                к&nbsp;своему обращению.
              </p>
            </div>
            <div
              v-if="error"
              class="form-error mt-m mb-m">
              Не удалось загрузить работу: {{ error }}
            </div>

            <BaseButton
              type="submit"
              class="mr-s"
              >Отправить</BaseButton
            >
            <BaseButton
              theme="primary-border"
              type="button"
              @click="$emit('close')"
              >Закрыть</BaseButton
            >
          </ValidationObserver>
        </BaseLoadingBox>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import FileInput from "@/components/FileInput";
import { talentRequest } from "@/services/api";
import { extend } from "vee-validate";
const ALLOWED_SOLUTION_TYPES = ["image/jpeg", "application/pdf"];
extend("solutions", {
  validate(files) {
    const uniqTypes = files.reduce((acc, file) => {
      if (acc.indexOf(file.type) === -1) {
        acc.push(file.type);
      }
      return acc;
    }, []);
    if (uniqTypes.length > 1) {
      return "Все файлы решения должны быть в одном формате: .pdf или .jpg";
    } else if (uniqTypes.length === 1) {
      return ALLOWED_SOLUTION_TYPES.includes(uniqTypes[0]);
    }
    return false;
  },
  message() {
    return "Загрузите файлы .pdf или .jpg";
  },
});
/**
 * Форма для загрузки работ по финалу
 */
export default {
  name: "WorkModal",
  components: {
    FileInput,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    profileId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        title_page: "",
        solutions: "",
      },
      error: "",
      pending: false,
      success: false,
      ALLOWED_SOLUTION_TYPES,
    };
  },
  computed: {
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    handleFileReset(name) {
      this.$refs[name]?.syncValue("");
      this.form[name] = "";
    },
    async handleFileChange(e, validate) {
      if (typeof validate === "function") {
        const isValid = await validate(e);
        if (isValid) {
          this.form[e.target.name] = e.target.files;
        }
      } else {
        this.form[e.target.name] = e.target.files;
      }
    },
    async handleSubmit() {
      const { title_page, solutions } = this.form;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      this.success = false;
      this.error = "";
      const fd = new FormData();
      fd.append("title_page", title_page[0]);
      [...solutions].forEach((file) => {
        fd.append(`solutions`, file);
      });

      try {
        const { data } = await talentRequest({
          method: "POST",
          url: `${this.$store.state.ntoToolsApiURL}/works/${this.subject.id}/upload/${this.user.talent_id}/`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: fd,
        });
        this.$store.commit("participant/ADD_WORK", data);
        this.success = true;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
