<template>
  <div
    v-if="showPanel"
    class="text-size-m">
    <div v-if="isUserControlled">
      <div v-if="status === statuses.WORK_NOT_UPLOADED && canUpload">
        <BaseButton
          v-if="canUpload"
          size="xs"
          theme="primary-border"
          class="mb-xs"
          @click.stop.prevent="openWorkModal(null)"
          >Загрузить работу</BaseButton
        >
        <div>
          <BaseIcon
            glyph="warn"
            class="color-error panel-icon" />
          Загрузите работу
          <span v-if="subject.formattedUploadDeadline">
            до {{ subject.formattedUploadDeadline }}</span
          >
        </div>
      </div>
      <!-- Работа загружена, но не на проверке -->
      <div v-else-if="status === statuses.WORK_NOT_ANALYZED && canUpload">
        <BaseIcon
          glyph="done"
          class="color-disabled panel-icon" />&nbsp;{{ workStatusText }},
        <a
          :href="`/client/talent${$store.state.ntoToolsApiURL}/works/${subject.id}/download/`"
          class="link panel-control"
          target="_blank"
          rel="noopener noreferrer"
          download
          >Скачать работу</a
        >
        <div v-if="canUpload">
          Если вы&nbsp;хотите обновить файлы загруженной работы,<br />то&nbsp;<a
            href="#"
            class="link panel-control"
            @click.stop.prevent="openWorkModal(null)"
            >загрузите их&nbsp;повторно</a
          >
          до&nbsp;{{ subject.formattedUploadDeadline }}.
        </div>
      </div>
      <!-- Работа проверятся экспертом -->
      <div v-else-if="status === statuses.WORK_ON_ANALYZE && canUpload">
        <BaseIcon
          glyph="waiting"
          class="color-secondary panel-icon" />&nbsp;{{ workStatusText }}
      </div>
      <!-- работа проверена экспертом --->
      <div v-else-if="status === statuses.WORK_ANALYZED">
        <BaseIcon
          glyph="done"
          class="color-success panel-icon" />&nbsp;{{ workStatusText }}. Вы
        набрали {{ analyzeScores }}.
        <a
          href="#"
          class="link panel-control"
          @click.prevent="openDetailModal"
          >Детализация баллов</a
        >
      </div>
      <!-- работа подана на апелляцию -->
      <div v-else-if="status === statuses.WORK_ON_APPEAL">
        <BaseIcon
          glyph="waiting"
          class="color-warn panel-icon" />&nbsp;{{ workStatusText }}.
        <a
          href="#"
          class="link panel-control"
          @click.prevent="openDetailModal"
          >Детализация баллов</a
        >
      </div>

      <!-- работа после апелляции -->
      <div v-else-if="status === statuses.WORK_AFTER_APPEAL">
        <BaseIcon
          glyph="waiting"
          class="color-success panel-icon" />&nbsp;{{ workStatusText }}.
        Итоговый балл {{ appealScores }}.
        <a
          href="#"
          class="link panel-control"
          @click.prevent="openDetailModal"
          >Детализация баллов</a
        >
      </div>

      <div v-else-if="subject && status !== 'not_started'">
        <BaseIcon
          glyph="waiting"
          class="color-disabled panel-icon" />&nbsp;Результаты будут
        опубликованы после &nbsp;{{ subject.formattedUploadDeadline }}
      </div>
    </div>
    <div v-else>
      <div v-if="status === statuses.WORK_ANALYZED">
        <BaseIcon
          glyph="done"
          class="color-success panel-icon" />&nbsp;{{ workStatusText }}. Вы
        набрали {{ analyzeScores }}.
        <a
          href="#"
          class="link panel-control"
          @click.prevent="openDetailModal"
          >Детализация баллов</a
        >
      </div>
      <!-- работа после апелляции -->
      <div v-else-if="status === statuses.WORK_AFTER_APPEAL">
        <BaseIcon
          glyph="waiting"
          class="color-success panel-icon" />&nbsp;{{ workStatusText }}.
        Итоговый балл {{ appealScores }}.
        <a
          href="#"
          class="link panel-control"
          @click.prevent="openDetailModal"
          >Детализация баллов</a
        >
      </div>
      <div v-else-if="subject && status !== 'not_started'">
        <BaseIcon
          glyph="waiting"
          class="color-disabled panel-icon" />&nbsp;Результаты будут
        опубликованы после &nbsp;{{ subject.formattedUploadDeadline }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  SUBJECT_OFFLINE,
  WORK_NOT_ANALYZED,
  WORK_ANALYZED,
  WORK_ON_ANALYZE,
  WORK_ON_APPEAL,
  WORK_AFTER_APPEAL,
  SUBJECT_STEPIK_OFFLINE,
  SUBJECT_EXTERNAL_PLATFORM,
} from "@/constants/works";
import dayjs from "@/plugins/dayjs";
import { DATE_FROMAT_WITH_UTC, MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import WorkModal from "@/components/user/modals/WorkModal";
import WorkDetailModal from "@/components/user/modals/WorkDetailModal";
import { numCases } from "@/utils";
import { getWorkStatus } from "@/utils/works";
export default {
  name: "WorkPanel",
  props: {
    activityId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    statuses() {
      return {
        WORK_NOT_ANALYZED,
        WORK_ANALYZED,
        WORK_ON_APPEAL,
        WORK_ON_ANALYZE,
        WORK_AFTER_APPEAL,
        WORK_NOT_UPLOADED: "not_uploaded",
      };
    },
    activity() {
      return this.$store.getters["participant/allActivities"][this.activityId];
    },
    subject() {
      const result =
        this.$store.state.participant.worksSubjects[this.activityId];
      if (!result) return;
      return {
        ...result,
        ...(result.upload_deadline && {
          formattedUploadDeadline: dayjs(result.upload_deadline).format(
            DATE_FROMAT_WITH_UTC
          ),
        }),
      };
    },
    work() {
      return this.$store.state.participant.works[this.subject?.id];
    },
    status() {
      const { work, isUserControlled, activity, syncTimeStamp } = this;
      if (dayjs(syncTimeStamp).isBefore(dayjs(activity?.start_at))) {
        return "not_started";
      }
      if (isUserControlled) {
        return work?.status || "not_uploaded";
      }
      return work?.status;
    },
    workStatusText() {
      const { work } = this;
      if (!work?.status) return "";
      return getWorkStatus(work);
    },
    showPanel() {
      const { activity, subject, status } = this;
      return (
        activity &&
        subject &&
        subject.type !== SUBJECT_OFFLINE &&
        status !== "not_started"
      );
    },
    /**
     * Является ли процесс загрузки работы
     * подкотрольным юзеру
     * @returns {boolean}
     */
    isUserControlled() {
      const subType = this.subject?.subject_type;
      return !!subType && subType !== SUBJECT_OFFLINE;
    },
    /**
     * Может ли юзер загрузить работу
     * @returns {boolean}
     */
    canUpload() {
      const { isUserControlled, subject } = this;
      return (
        isUserControlled &&
        ![SUBJECT_STEPIK_OFFLINE, SUBJECT_EXTERNAL_PLATFORM].includes(
          this.subject?.subject_type
        ) &&
        dayjs(this.syncTimeStamp).isBefore(dayjs(subject.upload_deadline))
      );
    },
    analyzeScores() {
      const { work } = this;
      if (!work) return 0;
      return `${parseFloat(work.analyse_scores.toFixed(2))} ${numCases(
        ["балл", "балла", "баллов"],
        work.analyse_scores
      )}`;
    },
    appealScores() {
      const { work } = this;
      if (!work) return 0;
      /**
       * Могут быть кейсы, когда апелляция проведена,
       * но ни один балл не поменялся, тогда бек вернет
       * `appeal_scores:0`. В этом случае, нужно вывести
       * `analyse_scores`
       */
      const actualScores =
        work.appeal_scores > 0 ? work.appeal_scores : work.analyse_scores;
      return `${parseFloat(actualScores.toFixed(2))} ${numCases(
        ["балл", "балла", "баллов"],
        actualScores
      )}`;
    },
  },
  methods: {
    openDetailModal() {
      this.$modal.show(
        WorkDetailModal,
        {
          subject: this.subject,
          profileId: this.activity.profiles[0],
          workId: this.work?.id,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openWorkModal() {
      this.$modal.show(
        WorkModal,
        {
          subject: this.subject,
          profileId: this.activity.profiles[0],
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
  },
};
</script>

<style lang="less" scoped>
.panel-icon {
  margin-top: -0.15em;
}
.panel-control {
  position: relative;
  z-index: 1;
}
</style>
