<template>
  <div class="file-input">
    <BaseInput
      class="file-input__name"
      readonly
      :value="fileName"
      :errors="errors"
      :placeholder="placeholder">
      <template #append>
        <button
          v-if="fileName"
          class="file-input__btn"
          type="button"
          @click="handleReset">
          <BaseIcon glyph="close" />
        </button>
        <label
          v-else
          :for="id"
          class="file-input__btn"
          type="button">
          <BaseIcon glyph="folder-open-o" />
        </label>

        <input
          v-bind="$attrs"
          :id="id"
          ref="file"
          class="file-input__file"
          @change="handleFileChange" />
      </template>
    </BaseInput>
  </div>
</template>

<script>
import { bytesToMB, numCases } from "@/utils";
export default {
  name: "FileInput",
  inheritAttrs: false,
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    errors: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: "Выбрать файл",
    },
  },
  data() {
    return {
      fileName: "",
    };
  },
  methods: {
    handleReset() {
      this.$refs.file.value = "";
      this.fileName = "";
      this.$emit("reset");
    },
    handleFileChange(e) {
      const files = [...e.target.files];
      let result = "";
      if (files.length > 1) {
        const size = files.reduce((acc, value) => acc + value.size, 0);
        result = `${files.length} ${numCases(
          ["файл", "файла", "файлов"],
          files.length
        )}, ${bytesToMB(size)} MB`;
      } else if (files.length === 1) {
        result = `${files[0].name}, ${bytesToMB(files[0].size)} MB`;
      }
      this.fileName = result;
      this.$emit("change", e);
    },
  },
};
</script>

<style lang="less" scoped>
.file-input {
  position: relative;

  &__file {
    width: 1px;
    height: 1px;
    position: absolute;
    left: -999em;
  }

  &__btn {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    outline: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @primary-color;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0;
    border-top-right-radius: @input-border-radius;
    border-bottom-right-radius: @input-border-radius;
    border: 0;
    font-size: 18px;

    &:hover {
      background-color: lighten(@primary-color, 10%);
    }

    &:active {
      background-color: darken(@primary-color, 10%);
    }
  }
}
</style>
